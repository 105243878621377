import { PINIA_STORES } from '@/constants/stores';
import { WebsiteType } from '@/types/aiBuilderTypes';
import {
	FEATURE_FLAG_IDS,
	getIsExperimentActive,
} from '@/utils/experiments';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAiBuilderPreviewStore = defineStore(PINIA_STORES.AI_BUILDER_PREVIEW, () => {
	const lastBrandName = ref('');
	const lastWebsiteDescription = ref('');
	const lastWebsiteType = ref<WebsiteType | undefined>();
	const aiFormVisible = ref(false);
	const currentVersionIndex = ref(0);
	const isLoadingEcommerceData = ref(false);

	const setLastAiGenerationData = ({
		brandName = '',
		websiteDescription = '',
		websiteType,
	}: {
		brandName: string;
		websiteDescription: string;
		websiteType: WebsiteType;
	}) => {
		if (!getIsExperimentActive(FEATURE_FLAG_IDS.AI_BUILDER_PREVIEW_REVAMP)) {
			return;
		}

		lastBrandName.value = brandName;
		lastWebsiteDescription.value = websiteDescription;
		lastWebsiteType.value = websiteType;
	};

	const getLastAiGenerationData = () => ({
		brandName: lastBrandName.value,
		websiteDescription: lastWebsiteDescription.value,
		websiteType: lastWebsiteType.value,
	});

	const setAiFormVisibility = (value: boolean) => {
		aiFormVisible.value = value;
	};

	const setCurrentVersionIndex = (value: number) => {
		currentVersionIndex.value = value;
	};

	const setIsLoadingEcommerceData = (value: boolean) => {
		isLoadingEcommerceData.value = value;
	};

	return {
		lastBrandName,
		lastWebsiteDescription,
		lastWebsiteType,
		setLastAiGenerationData,
		getLastAiGenerationData,
		setAiFormVisibility,
		aiFormVisible,
		currentVersionIndex,
		setCurrentVersionIndex,
		setIsLoadingEcommerceData,
		isLoadingEcommerceData,
	};
});
