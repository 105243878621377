import {
	getIsExperimentActive,
	FEATURE_FLAG_IDS,
} from '@/utils/experiments';
import {
	computed,
	ref,
} from 'vue';
import { useStorage } from '@vueuse/core';
import { SiteData } from '@hostinger/builder-schema-validator';
import { useSiteStore } from '@/stores/siteStore';
import { useStore } from 'vuex';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { createStoreWithProducts } from '@/api/EcommerceApi';
import { HTML_LANG_VALUES } from '@/data/htmlLangValues';
import {
	SITE_HISTORY_KEY,
	SITE_HISTORY_LIMIT,
} from '@/constants';
import { DEFAULT_HTML_LANG_VALUE } from '@zyro-inc/site-modules/constants';
import { useAiBuilderPreviewStore } from '@/stores/aiBuilderPreviewStore';
import { useAiBuilderForm } from '@/components/ai-builder/useAiBuilderForm';
import { generateSite } from '@/api/AiApiV2';
import { useNotifications } from '@/use/useNotifications';
import { useI18n } from 'vue-i18n';
import { AxiosError } from 'axios';

interface SiteHistoryRecord {
	language: string;
	siteData: SiteData;
	ecommerceProducts: any;
	timestamp?: number;
}

enum AiPreviewGeneratorState {
	IDLE = 'IDLE',
	GENERATING = 'GENERATING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

export const useAiBuilderPreview = () => {
	const { t } = useI18n();
	const { notify } = useNotifications();
	const { dispatch } = useStore();
	const siteStore = useSiteStore();
	const ecommerceStore = useEcommerceStore();
	const aiBuilderPreviewStore = useAiBuilderPreviewStore();

	const {
		setDescriptionValue,
		setBusinessTypeId,
		setBrandName,
		brandDescription,
		websiteType: websiteTypeId,
		brandName,
		AI_BUILDER_CATEGORIES,
	} = useAiBuilderForm();

	const aiPreviewGeneratorState = ref<AiPreviewGeneratorState>(AiPreviewGeneratorState.IDLE);

	const generatedSites = useStorage<SiteHistoryRecord[]>(SITE_HISTORY_KEY, []);
	const isAiPreviewLoading = computed(() => aiPreviewGeneratorState.value === AiPreviewGeneratorState.GENERATING);
	const generatedSitesCount = computed(() => generatedSites.value.length);

	const getIsAiBuilderPreviewExperimentActive = () => getIsExperimentActive(FEATURE_FLAG_IDS.AI_BUILDER_PREVIEW_REVAMP);

	const saveGeneratedSite = (site: SiteHistoryRecord) => {
		if (!getIsAiBuilderPreviewExperimentActive()) {
			return;
		}

		if (generatedSitesCount.value >= SITE_HISTORY_LIMIT) {
			generatedSites.value.pop();
		}

		generatedSites.value = [
			{
				...site,
				timestamp: Date.now(),
			},
			...generatedSites.value,
		];
	};

	const setNewPreviewSite = async (siteHistoryIndex: number) => {
		const {
			language,
			siteData,
			ecommerceProducts,
		} = generatedSites.value[siteHistoryIndex] || {};

		if (!siteData) {
			throw new Error('Site record does not exist');
		}

		aiBuilderPreviewStore.setCurrentVersionIndex(siteHistoryIndex);

		siteStore.setSiteData(siteData);

		dispatch('updateCurrentPageId');

		await dispatch('gui/updatePreviewSiteData', {
			...siteStore.site,
			siteId: siteStore.websiteId,
		});

		if (ecommerceProducts?.length) {
			const languageCode = HTML_LANG_VALUES.find(
				(lang) => lang.title.toLowerCase() === language?.toLowerCase(),
			)?.value?.toUpperCase();

			try {
				aiBuilderPreviewStore.setIsLoadingEcommerceData(true);

				const { storeId } = await createStoreWithProducts({
					siteId: siteStore.websiteId,
					products: ecommerceProducts,
					language: languageCode || DEFAULT_HTML_LANG_VALUE.value,
				});

				siteStore.setSiteMetaData({
					key: 'ecommerceStoreId',
					value: storeId,
				});

				await ecommerceStore.fetchInitialEcommerceData({
					shouldAwaitPageCreation: true,
				});
			} catch (error) {
				console.error('Failed to create ecommerce store', error);
			} finally {
				aiBuilderPreviewStore.setIsLoadingEcommerceData(false);
			}
		}
	};

	const handleCreateAgainClick = () => {
		aiBuilderPreviewStore.setAiFormVisibility(true);

		const {
			websiteDescription: lastWebsiteDescription,
			websiteType: lastWebsiteType,
			brandName: lastBrandName,
		} = aiBuilderPreviewStore.getLastAiGenerationData();

		setDescriptionValue(lastWebsiteDescription);
		setBusinessTypeId(lastWebsiteType);
		setBrandName(lastBrandName);
	};

	const handleAiFormSubmit = async () => {
		if (aiPreviewGeneratorState.value !== AiPreviewGeneratorState.IDLE) {
			return;
		}

		if (!websiteTypeId.value) {
			return;
		}

		aiPreviewGeneratorState.value = AiPreviewGeneratorState.GENERATING;

		let generatedSiteData;

		try {
			const { data } = await generateSite({
				brandName: brandName.value,
				websiteType: AI_BUILDER_CATEGORIES[websiteTypeId.value].amplitudeName,
				brandDescription: brandDescription.value,
				siteId: siteStore.websiteId,
			});

			dispatch('saving/updateClientTimestamp', data.clientTimestamp);

			generatedSiteData = data;
			aiPreviewGeneratorState.value = AiPreviewGeneratorState.SUCCESS;
		} catch (error) {
			if (error instanceof AxiosError && error.response?.status === 422) {
				notify({
					message: t('builder.aiBuilderInvalidWebsiteDescription'),
				});
			}

			aiPreviewGeneratorState.value = AiPreviewGeneratorState.ERROR;
			throw error;
		} finally {
			aiPreviewGeneratorState.value = AiPreviewGeneratorState.IDLE;
		}

		saveGeneratedSite({
			language: generatedSiteData.language,
			siteData: generatedSiteData.siteData,
			ecommerceProducts: generatedSiteData.ecommerceProducts,
		});

		setNewPreviewSite(0);

		aiBuilderPreviewStore.setAiFormVisibility(false);
	};

	return {
		isAiPreviewLoading,
		getIsAiBuilderPreviewExperimentActive,
		saveGeneratedSite,
		setNewPreviewSite,
		handleCreateAgainClick,
		handleAiFormSubmit,
		generatedSites,
	};
};
